import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Badge(
  { badge, selected, handleClick, size }
) {
  const classNames = classnames(
    'shout-out-badge',
    `badge-${size}`,
    { selected }
  );

  return (
    <div
      className={classNames}
      onClick={() => handleClick(badge)}
    >
      <span className="emoji">
        {badge.emoji}
      </span>
      &nbsp;
      <span className="label">
        {badge.label}
      </span>
    </div>
  );
}

export const BadgeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired,
  sampleNote: PropTypes.string,
});

Badge.propTypes = {
  badge: BadgeType.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']).isRequired,
};

Badge.defaultProps = {
  selected: false,
};
