import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { shoutOutSentConfirmationDismissed } from '../slices/ui';

const displayOptions = [
  {
    name: 'Small Dog',
    prompt: "Don't leave nurses hanging —send some more love!",
    gifSrc: 'https://media.giphy.com/media/kgJRF8sLkVP70LXeJh/source.gif',
    gifHeight: 220,
  },
  {
    name: 'McDonalds',
    prompt: "Don't stop now! You've got more Shout Outs to send!",
    gifSrc: 'https://media.giphy.com/media/USf0phFsOCi2uKIKfn/source.gif',
    gifHeight: 190,
  },
  {
    name: 'The Office',
    prompt: "Boom! Let's send another...",
    gifSrc: 'https://media.giphy.com/media/1ThndC5odGuUU/giphy.gif',
    gifHeight: 229,
  },
  {
    name: 'Elmo',
    prompt: 'Keep the party going! More nurses need Shout Outs!',
    gifSrc: 'https://media.giphy.com/media/33V9dnkTaxN7bzYi2b/source.gif',
    gifHeight: 206,
  },
  {
    name: 'Jet Ski Dog',
    prompt: "You've got more in the tank... Let's send another!",
    gifSrc: 'https://media.giphy.com/media/bcaThPwQ6naOxrMHGR/source.gif',
    gifHeight: 204,
  },
];

export default function ConfirmationBlock() {
  const shoutOutAllowance = useSelector(state => state.user.shoutOutAllowance);
  const displayOptionIndex = useSelector(
    state => state.ui.shoutOutConfirmationGifIndex
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (shoutOutAllowance > 0) {
      setTimeout(
        () => dispatch(shoutOutSentConfirmationDismissed()),
        3500
      );
    }
  }, []);

  const displayOption = displayOptions[
    displayOptionIndex % displayOptions.length
  ];

  return (
    <Row>
      <Col xs={12} className="confirmation-block">
        <header>
          Shout Out Sent
        </header>
        <p className="confirmation-prompt">
          {displayOption.prompt}
        </p>
        <div className="gif-wrapper">
          <img
            width={275}
            height={displayOption.gifHeight}
            src={displayOption.gifSrc}
            alt={displayOption.name}
          />
        </div>
      </Col>
    </Row>
  );
}
