import { combineReducers } from 'redux';

import shoutOutsReducer from './slices/shoutOuts';
import uiReducer from './slices/ui';
import userReducer from './slices/user';
import userShoutOutsReducer from './slices/userShoutOuts';

export default combineReducers({
  badges: state => state || [],
  featureFlags: state => state || [],
  shoutOuts: shoutOutsReducer,
  ui: uiReducer,
  user: userReducer,
  userShoutOuts: userShoutOutsReducer,
});
