import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import FeedFooter from '../components/FeedFooter';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ShoutOutList from '../components/ShoutOutList';
import { fetchOlder } from '../slices/shoutOuts';

export default function Feed() {
  const { data: shoutOuts, hasMore } = useSelector(state => state.shoutOuts);
  const dispatch = useDispatch();

  return (
    <div className="feed-container">
      <Helmet>
        <title>Nurse Shout Outs - Shout Out Feed</title>
      </Helmet>
      <ScrollToTopOnMount />
      <ShoutOutList
        shoutOuts={shoutOuts}
        hasMore={hasMore}
        loadMore={() => dispatch(fetchOlder())}
        footer={<FeedFooter shoutOuts={shoutOuts} />}
      />
    </div>
  );
}
