import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import SVG from 'react-inlinesvg';
import { Helmet } from 'react-helmet';
import ReactPhoneNumberInput from 'react-phone-number-input/input';

import closeIcon from 'images/icons/close-bolded.svg';
import loupeIcon from 'images/icons/loupe.svg';

import ConfirmationModal from '../components/ConfirmationModal';
import FeedFooter from '../components/FeedFooter';
import ShoutOutList from '../components/ShoutOutList';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { fetchByPhone, fetchOlder } from '../slices/userShoutOuts';
import { formatPhoneNumber } from '../utils/formatters';
import { parsePhoneNumber } from '../utils/parsers';
import { track } from '../utils/tracking';

function PhoneLabel({ number, clearNumber }) {
  const wrappedClearNumber = () => {
    clearNumber();
    track('clear.phone_filter');
  };

  return (
    <Row className="phone-filter-container" noGutters>
      <div className="label">
        <span>
          {formatPhoneNumber(number)}
        </span>
        <div
          className="clear-number"
          onClick={wrappedClearNumber}
        >
          <SVG className="icon" src={closeIcon} />
        </div>
      </div>
    </Row>
  );
}

PhoneLabel.propTypes = {
  clearNumber: PropTypes.func.isRequired,
  number: PropTypes.string.isRequired,
};

function PhoneInput({ handleSubmit }) {
  const [state, setState] = useState({ number: '', error: '' });

  const onSubmit = () => {
    const { number } = state;

    const validatedNumber = parsePhoneNumber(number);
    if (!validatedNumber) {
      setState(prevState => ({
        ...prevState,
        error: 'Please enter a valid phone number',
      }));
    } else {
      handleSubmit(validatedNumber);
    }

    track('click.lookup_number', {
      Number: number,
      'Valid Number': !!validatedNumber,
    });
  };

  const onKeyDown = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <Row className="phone-filter-container" noGutters>
      <p className="prompt">
        Enter your phone number to see your sent & received shout outs.
      </p>

      <div className="phone-input">
        <ReactPhoneNumberInput
          country="US"
          value={state.number}
          onChange={number => setState({ number, error: '' })}
          onKeyDown={onKeyDown}
        />
        <div
          className="search-btn"
          onClick={onSubmit}
        >
          <SVG className="icon" src={loupeIcon} />
        </div>
        {
          state.error && (
            <div className="input-error">
              {state.error}
            </div>
          )
        }
      </div>
    </Row>
  );
}

PhoneInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default function UserFeed() {
  const {
    phone,
    data: shoutOuts,
    hasMore,
  } = useSelector(state => state.userShoutOuts);

  const [number, setNumber] = useState(phone);
  const dispatch = useDispatch();

  const lookupShoutOuts = num => {
    setNumber(num);
    dispatch(fetchByPhone(num));
    track('request.user_shout_outs', { Number: num });
  };

  let mainContent = null;
  if (number) {
    mainContent = (
      <>
        <PhoneLabel
          number={number}
          clearNumber={() => setNumber('')}
        />
        <ShoutOutList
          shoutOuts={shoutOuts}
          hasMore={hasMore}
          loadMore={() => dispatch(fetchOlder())}
          footer={<FeedFooter isUserFeed shoutOuts={shoutOuts} />}
        />
      </>
    );
  } else {
    mainContent = (
      <>
        <PhoneInput handleSubmit={lookupShoutOuts} />
        <ShoutOutList shoutOuts={[]} />
      </>
    );
  }

  return (
    <div className="user-feed-container">
      <Helmet>
        <title>Nurse Shout Outs - My Shout Out Feed</title>
      </Helmet>
      <ScrollToTopOnMount />
      <ConfirmationModal />

      {mainContent}
    </div>
  );
}
