import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Container from 'react-bootstrap/Container';
import InfiniteScroll from 'react-infinite-scroll-component';
import SVG from 'react-inlinesvg';

import spinnerIcon from 'images/icons/spinner.svg';

import useSendShoutOutEnabled from '../hooks/useSendShoutOutEnabled';
import { track } from '../utils/tracking';
import AppButton from './AppButton';
import ShoutOut from './ShoutOut';

function LoadingIndicator() {
  return (
    <div className="loading-indicator">
      <SVG className="icon icon-right" src={spinnerIcon} />
    </div>
  );
}

export default function ShoutOutList(
  { shoutOuts, hasMore, loadMore, footer }
) {
  const sendEnabled = useSendShoutOutEnabled();
  const history = useHistory();

  const wrappedLoadMore = () => {
    const { pathname } = window.location;
    track(
      'load.shout_outs',
      { Page: pathname, 'Current Count': shoutOuts.length }
    );

    loadMore();
  };

  return (
    <Container className="shout-out-list">
      <InfiniteScroll
        dataLength={shoutOuts.length}
        next={wrappedLoadMore}
        hasMore={hasMore}
        loader={<LoadingIndicator />}
        endMessage={footer}
      >
        {shoutOuts.map(so => <ShoutOut key={so.id} shoutOut={so} />)}
      </InfiniteScroll>

      {
        sendEnabled && (
          <AppButton
            className="create-btn"
            icon="arrowRight"
            clickName="send_shout_out"
            onClick={() => history.push('/app/send')}
            text="Send a Shout Out"
          />
        )
      }
    </Container>
  );
}

ShoutOutList.propTypes = {
  footer: PropTypes.node,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  shoutOuts: PropTypes.array.isRequired,
};

ShoutOutList.defaultProps = {
  footer: null,
  hasMore: false,
  loadMore: () => null,
};
