import { getAuthenticityToken } from './rails';

export async function createShoutOut(data) {
  const response = await fetch(
    '/shout_outs',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getAuthenticityToken(),
      },
      body: JSON.stringify(data),
    }
  );

  return response.json();
}
