import React from 'react';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Sentry from '@sentry/react';

import Feed from './containers/Feed';
import UserFeed from './containers/UserFeed';
import HowItWorks from './containers/HowItWorks';
import SendShoutOutWrapper from './containers/SendShoutOutWrapper';
import AppNav from './components/AppNav';
import Observer from './components/Observer';

const App = ({ store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <Observer />

      <Container className="app px-0" fluid="sm">
        <Row className="mx-auto">
          <Col
            sm={{ offset: 1, span: 10 }}
            md={{ offset: 2, span: 8 }}
            lg={{ offset: 3, span: 6 }}
            className="px-0"
          >
            <AppNav />

            <Switch>
              <Redirect
                exact
                from="/app"
                to="/app/feed"
              />

              <Route
                path="/app/feed"
                component={Feed}
              />

              <Route
                path="/app/my-feed"
                component={UserFeed}
              />

              <Route
                path="/app/how-it-works"
                component={HowItWorks}
              />

              <Route
                path="/app/send"
                component={SendShoutOutWrapper}
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  </Provider>
);

export default Sentry.withErrorBoundary(hot(App));
