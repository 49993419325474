import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

export default function ShoutOut({ shoutOut }) {
  const date = moment(shoutOut.date).fromNow();

  return (
    <Row className="shout-out">
      <Col className="px-0">
        <div className="recipient">
          To {shoutOut.recipient.name}
        </div>
        <div className="shout-out-inner">
          <h6 className="badge">
            {shoutOut.badge.emoji} {shoutOut.badge.label}
          </h6>

          {
            shoutOut.note && (
              <p className="note">
                {shoutOut.note}
              </p>
            )
          }

          <div className="d-flex justify-content-between">
            <div className="date">
              {date}
            </div>
            <div className="sender">
              From {shoutOut.sender.name}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

ShoutOut.propTypes = {
  shoutOut: PropTypes.object.isRequired,
};
