import Mixpanel from 'mixpanel-browser';

export function identify(...args) {
  if (window.MixpanelToken) {
    Mixpanel.identify(...args);
  }
}

export function register(...args) {
  if (window.MixpanelToken) {
    Mixpanel.register(...args);
  }
}

export function track(...args) {
  if (window.MixpanelToken) {
    Mixpanel.track(...args);
  }

  if (window.fbq) {
    const eventName = args[0];
    switch (eventName) {
      case 'click.send_shout_out':
        fbq('track', 'AddToCart');
        break;

      case 'click.submit_shout_out':
        fbq('track', 'Purchase', { currency: 'USD', value: 10.00 });
        break;

      default:
        break;
    }
  }
}
