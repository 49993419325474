import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function FeedFooter({ shoutOuts, isUserFeed }) {
  const userKind = useSelector(state => state.user.kind);

  let fact = null;
  let callToAction = null;
  let gif = null;

  if (!isUserFeed || shoutOuts.length >= 3) {
    callToAction = (
      <p>
        That&apos;s all for now. Send a Shout Out to keep the good vibes
        flowing!
      </p>
    );
  } else {
    gif = (
      <div>
        <img
          width={315}
          height={171}
          src="https://media.giphy.com/media/c5RzZOHPgry80/source.gif"
          alt="Let's go!"
        />
      </div>
    );

    if (shoutOuts.length === 0) {
      fact = userKind === 'nurse' ?
        (
          <p>
            It doesn&apos;t look like you&apos;ve sent or received any
            Shout Outs yet.
          </p>
        ) :
        (
          <p>
            It doesn&apos;t look like you&apos;ve sent any Shout Outs yet.
          </p>
        );

      callToAction = userKind === 'nurse' ?
        (
          <p>Get the party started and send your buddies some Shout Outs!</p>
        ) :
        (
          <p>
            Get the party started and send the awesome nurses in your life
            some Shout Outs!
          </p>
        );
    } else {
      callToAction = userKind === 'nurse' ?
        (
          <p>Keep the party rolling and send your buddies some Shout Outs!</p>
        ) :
        (
          <p>Keep the party rolling and send some Shout Outs!</p>
        );
    }
  }

  return (
    <div className="feed-footer">
      {fact}
      {callToAction}
      {gif}
    </div>
  );
}

FeedFooter.propTypes = {
  isUserFeed: PropTypes.bool,
  shoutOuts: PropTypes.array.isRequired,
};

FeedFooter.defaultProps = {
  isUserFeed: false,
};
