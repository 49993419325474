import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import once from 'lodash/once';
import ReactPhoneNumberInput from 'react-phone-number-input/input';

import closeIcon from 'images/icons/close.svg';
import { track } from '../utils/tracking';

export default function Input(props) {
  const {
    className,
    controlId,
    error,
    eventControlPrefix,
    label,
    onChange,
    optional,
    type,
    value,
    xs,
    ...extraProps
  } = props;

  const inputRef = useRef(null);

  const clear = () => {
    onChange('');
    inputRef.current.focus();

    const eventName = ['clear', eventControlPrefix, controlId].join('.');
    track(eventName);
  };

  const trackEvent = useRef(once(() => {
    const eventName = ['input', eventControlPrefix, controlId].join('.');
    track(eventName);
  }));

  const wrappedOnChange = arg => {
    if (type === 'tel') {
      onChange(arg);
    } else {
      onChange(arg.target.value);
    }

    trackEvent.current();
  };

  const extraControlProps = { ...extraProps };
  if (type === 'tel') {
    extraControlProps.as = ReactPhoneNumberInput;
    extraControlProps.country = 'US';
  } else if (type === 'textarea') {
    extraControlProps.as = 'textarea';
  }

  return (
    <>
      <Form.Group
        className={classnames('app-input', { filled: value !== '' }, className)}
        as={Col}
        xs={xs}
        controlId={controlId}
      >
        <Form.Control
          ref={inputRef}
          value={value}
          onChange={wrappedOnChange}
          {...extraControlProps}
        />
        <Form.Label className={classnames({ optional })}>
          {label}
        </Form.Label>
        <div
          className="close-btn"
          onClick={clear}
        >
          <SVG className="icon" src={closeIcon} />
        </div>
      </Form.Group>
      {
        error && (
          <div className="input-error">
            {error}
          </div>
        )
      }
    </>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  controlId: PropTypes.string.isRequired,
  error: PropTypes.string,
  eventControlPrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  xs: PropTypes.number,
};

Input.defaultProps = {
  className: null,
  error: null,
  optional: false,
  type: 'text',
  value: '',
  xs: 12,
};
