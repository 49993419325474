import { createSlice } from '@reduxjs/toolkit';

export const makeDefaultUser = () => ({
  id: '',
  name: '',
  kind: '',
  phone: '',
  shoutOutAllowance: 5,
});

export const userSlice = createSlice({
  name: 'user',
  initialState: makeDefaultUser(),
  reducers: {
    updateUser: (state, { payload }) => payload,
  },
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
