import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import camelCaseKeys from 'camelcase-keys';

export const fetchByPhone = createAsyncThunk(
  'userShoutOuts/fetchByPhone',
  async phone => {
    const response = await fetch(
      `/shout_outs?${new URLSearchParams({ phone })}`
    );

    return response.json();
  }
);

export const fetchOlder = createAsyncThunk(
  'userShoutOuts/fetchOlder',
  async (_, { getState }) => {
    const { userShoutOuts: { phone, olderCursor } } = getState();
    const response = await fetch(
      `/shout_outs?${new URLSearchParams({ phone, cursor: olderCursor })}`
    );

    return response.json();
  }
);

export const userShoutOutsSlice = createSlice({
  name: 'userShoutOuts',
  initialState: {
    phone: '',
    data: [],
    hasMore: true,
    olderCursor: '',
    newerCursor: '',
    requestInProgress: false,
  },
  reducers: {
    // Nothing
  },
  extraReducers: {
    [fetchByPhone.pending]: (state, { meta }) => {
      const { arg: phone } = meta;
      state.requestInProgress = true;
      state.phone = phone;
      state.data = [];
    },
    [fetchByPhone.fulfilled]: (state, { payload }) => {
      state.requestInProgress = false;

      const { data, hasMore, olderCursor } = camelCaseKeys(payload);
      state.data.push(...data);
      state.hasMore = hasMore;
      state.olderCursor = olderCursor;
    },
    [fetchByPhone.rejected]: state => {
      state.requestInProgress = false;
      state.hasMore = false;
    },
    [fetchOlder.pending]: state => {
      state.requestInProgress = true;
    },
    [fetchOlder.fulfilled]: (state, { payload }) => {
      state.requestInProgress = false;

      const { data, hasMore, olderCursor } = camelCaseKeys(payload);
      state.data.push(...data);
      state.hasMore = hasMore;
      state.olderCursor = olderCursor;
    },
    [fetchOlder.rejected]: state => {
      state.requestInProgress = false;
      state.hasMore = false;
    },
  },
});

export default userShoutOutsSlice.reducer;
