import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import AppButton from '../components/AppButton';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import useSendShoutOutEnabled from '../hooks/useSendShoutOutEnabled';

export function HowItWorks({ history }) {
  const userKind = useSelector(state => state.user.kind);
  const sendEnabled = useSendShoutOutEnabled();

  const gifSrc =
    'https://media.giphy.com/media/uWlpPGquhGZNFzY90z/giphy-downsized.gif';

  return (
    <div className="how-it-works-container">
      <ScrollToTopOnMount />
      <Helmet>
        <title>Nurse Shout Outs - How It Works</title>
      </Helmet>
      <div>
        <img
          width={280}
          height={205}
          src={gifSrc}
          alt="Thank You!"
        />
      </div>
      {
        userKind === 'nurse' ?
          (
            <p>
              Every nurse has their favorite lift partner 💪  or a code brown
              buddy that&apos;s willing to help in a &quot;sticky&quot;
              situation 💩. Show them appreciation and have a laugh by sending
              a quick Shout Out.
            </p>
          ) :
          (
            <p>
              Don&apos;t let the nurses in your life go underappreciated. Give
              them a Shout Out to let them know you care.
            </p>
          )
      }

      <p>
        Plus they&apos;ll be entered to win Starbucks and Amazon gift cards!
      </p>

      {
        sendEnabled && (
          <AppButton
            className="create-btn"
            icon="arrowRight"
            clickName="send_shout_out"
            onClick={() => history.push('/app/send')}
            text="Send a Shout Out"
          />
        )
      }
    </div>
  );
}

HowItWorks.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(HowItWorks);
