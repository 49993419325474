import { configureStore } from '@reduxjs/toolkit';
import camelCaseKeys from 'camelcase-keys';
import * as Sentry from '@sentry/react';

import rootReducer from './reducers';
import { makeDefaultUser } from './slices/user';

const genGetDataAttributeFromNode = node => (
  attribute => {
    const dataAttribute = node.getAttribute(`data-${attribute}`);
    return camelCaseKeys(JSON.parse(dataAttribute));
  }
);

function getInitialState(appNode) {
  const getRailsProp = genGetDataAttributeFromNode(appNode);

  const state = {
    badges: getRailsProp('badges').map(b => camelCaseKeys(b)),
    featureFlags: getRailsProp('feature_flags'),
    shoutOuts: getRailsProp('shout_outs'),
  };

  const user = getRailsProp('user');
  if (user) {
    state.user = user;
    state.userShoutOuts = {
      phone: user.phone,
      ...getRailsProp('user_shout_outs'),
    };
  } else {
    state.user = {
      ...makeDefaultUser(),
      kind: getRailsProp('kind'),
    };
  }

  return state;
}

export default function configureAppStore(appNode) {
  const preloadedState = getInitialState(appNode);
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    enhancers: [sentryReduxEnhancer],
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
