import React from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import closeIcon from 'images/icons/close.svg';

import AppButton from './AppButton';
import useSendShoutOutEnabled from '../hooks/useSendShoutOutEnabled';
import { shoutOutSentConfirmationDismissed } from '../slices/ui';

export default function ConfirmationModal() {
  const show = useSelector(state => state.ui.showShoutOutSentConfirmation);
  const sendEnabled = useSendShoutOutEnabled();
  const gifIndex = useSelector(state => state.ui.shoutOutConfirmationGifIndex);

  const history = useHistory();
  const dispatch = useDispatch();

  let celebrationGIF;
  if (sendEnabled) {
    const celebrationGIFOptions = [
      {
        name: 'The Office',
        src: 'https://media.giphy.com/media/1ThndC5odGuUU/giphy.gif',
        height: 207,
      },
      {
        name: 'Elmo',
        src: 'https://media.giphy.com/media/kyLYXonQYYfwYDIeZl/source.gif',
        height: 207,
      },
      {
        name: 'Will Ferrell',
        src: 'https://media.giphy.com/media/axu6dFuca4HKM/source.gif',
        height: 131,
      },
    ];
    celebrationGIF = celebrationGIFOptions[
      gifIndex % celebrationGIFOptions.length
    ];
  } else {
    celebrationGIF = {
      name: 'Cool Cow',
      src: 'https://media.giphy.com/media/ydSEl8zXXKpVJASzDF/source.gif',
      height: 242,
    };
  }

  return (
    <Modal
      className="confirmation-modal"
      onHide={() => dispatch(shoutOutSentConfirmationDismissed())}
      show={show}
    >
      <div
        className="close-btn"
        onClick={() => dispatch(shoutOutSentConfirmationDismissed())}
      >
        <img className="icon" src={closeIcon} alt="Close" />
      </div>
      <Modal.Header>
        Shout Out Sent!
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            width={275}
            height={celebrationGIF.height}
            src={celebrationGIF.src}
            alt={celebrationGIF.name}
          />
        </div>
        {
          sendEnabled ?
            (
              <>
                <div>
                  They&apos;ve been entered to win gift cards! Send another
                  Shout Out to keep the fun going!
                </div>
                <AppButton
                  icon="arrowRight"
                  clickName="send_another_shout_out"
                  onClick={() => history.push('/app/send')}
                  text="Send another Shout Out"
                />
              </>
            ) :
            (
              <div>
                <p>Wow, you&apos;re a certified Shout Out master! 🙏</p>
                <p>Come back tomorrow and you can send 5 more.</p>
              </div>
            )
        }
      </Modal.Body>
    </Modal>
  );
}
