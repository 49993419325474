import React from 'react';
import { useSelector } from 'react-redux';

import SendShoutOut from './SendShoutOut';
import SendShoutOutWithCountdown from './SendShoutOutWithCountdown';

export default function SendShoutOutWrapper() {
  const featureFlags = useSelector(state => state.featureFlags);

  if (featureFlags.includes('limited_shout_outs')) {
    return <SendShoutOutWithCountdown />;
  }

  return <SendShoutOut />;
}
