import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SVG from 'react-inlinesvg';
import { Helmet } from 'react-helmet';

import arrowLeftIcon from 'images/icons/arrow-left.svg';

import ShoutOutFormWithCountdown from '../components/ShoutOutFormWithCountdown';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { shoutOutSent } from '../slices/ui';

export function SendShoutOutWithCountdown({ history }) {
  const dispatch = useDispatch();

  const onSubmit = user => {
    dispatch(shoutOutSent({ gifIndex: user.shoutOutAllowance }));
    if (user.shoutOutAllowance <= 0) {
      history.push('/app/my-feed');
    }
  };

  return (
    <Container className="send-container px-0" fluid>
      <Helmet>
        <title>Nurse Shout Outs - Send a Shout Out</title>
      </Helmet>
      <ScrollToTopOnMount />
      <Row className="header" noGutters>
        <div
          className="back"
          onClick={() => history.goBack()}
        >
          <SVG className="icon icon-left" src={arrowLeftIcon} />
          Back
        </div>
        <Col xs={{ span: 6, offset: 3 }}>
          Send a Shout Out
        </Col>
      </Row>

      <ShoutOutFormWithCountdown
        handleSubmit={onSubmit}
      />
    </Container>
  );
}

SendShoutOutWithCountdown.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SendShoutOutWithCountdown);
