import { parseFullName, parsePhoneNumber } from './parsers';

export function validateShoutOut(
  { recipientName, recipientPhone, senderName, senderPhone, badge, note }
) {
  const data = {
    recipient: {},
    sender: {},
    note,
  };
  const errors = new Map();

  const recipientNameParts = parseFullName(recipientName);
  if (recipientNameParts.length < 2) {
    errors.set('recipientName', 'Please enter both a first and last name');
  } else {
    data.recipient.first_name = recipientNameParts[0];
    data.recipient.last_name = recipientNameParts[1];
  }

  const validatedRecipientPhone = parsePhoneNumber(recipientPhone);
  if (!validatedRecipientPhone) {
    errors.set('recipientPhone', 'Please enter a valid phone number');
  } else {
    data.recipient.phone = validatedRecipientPhone;
  }

  const senderNameParts = parseFullName(senderName);
  if (senderNameParts.length < 2) {
    errors.set('senderName', 'Please enter both a first and last name');
  } else {
    data.sender.first_name = senderNameParts[0];
    data.sender.last_name = senderNameParts[1];
  }

  const validatedSenderPhone = parsePhoneNumber(senderPhone);
  if (!validatedSenderPhone) {
    errors.set('senderPhone', 'Please enter a valid phone number');
  } else {
    data.sender.phone = validatedSenderPhone;
  }

  if (badge === null) {
    errors.set('badge', 'Please select a badge');
  } else {
    data.badge = { id: badge.id };
  }

  return { data, errors };
}
