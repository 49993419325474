import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import SVG from 'react-inlinesvg';

import arrowRightIcon from 'images/icons/arrow-right.svg';
import paperPlaneIcon from 'images/icons/paper-plane.svg';
import spinnerIcon from 'images/icons/spinner.svg';

import { track } from '../utils/tracking';

export default function AppButton(props) {
  const { icon, text, clickName, onClick, ...otherProps } = props;

  let iconImgSrc;
  if (icon) {
    iconImgSrc = {
      arrowRight: arrowRightIcon,
      paperPlane: paperPlaneIcon,
      spinner: spinnerIcon,
    }[icon];
  }

  const wrappedOnClick = (...args) => {
    const { pathname } = window.location;
    track(`click.${clickName}`, { Page: pathname });

    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <Button variant="app" onClick={wrappedOnClick} {...otherProps}>
      {text}
      {
        iconImgSrc && (
          <SVG className="icon icon-right" src={iconImgSrc} />
        )
      }
    </Button>
  );
}

AppButton.propTypes = {
  clickName: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

AppButton.defaultProps = {
  icon: null,
};
