import { createSlice } from '@reduxjs/toolkit';

function randomInt(maxValue = 1000) {
  return Math.floor(Math.random() * (maxValue + 1));
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    showNav: false,
    showShoutOutSentConfirmation: false,
    shoutOutConfirmationGifIndex: randomInt(),
  },
  reducers: {
    locationChange: (state, { payload }) => {
      const { pathname } = payload.location;
      if (pathname === '/app/send') {
        state.showNav = false;
      } else {
        state.showNav = true;
      }
    },
    shoutOutSent: state => {
      state.showShoutOutSentConfirmation = true;
      state.shoutOutConfirmationGifIndex = randomInt();
    },
    shoutOutSentConfirmationDismissed: state => {
      state.showShoutOutSentConfirmation = false;
    },
  },
});

export const {
  locationChange,
  shoutOutSent,
  shoutOutSentConfirmationDismissed,
} = uiSlice.actions;

export default uiSlice.reducer;
