import libParsePhoneNumber from 'libphonenumber-js';

export function parseFullName(name) {
  return (name || '')
    .trim()
    .split(/ (.+)/)
    .map(s => s.trim())
    .slice(0, 2);
}

export function parsePhoneNumber(phoneNumber) {
  try {
    const parsedNumber = libParsePhoneNumber(phoneNumber, 'US');
    if (parsedNumber.isValid()) {
      return parsedNumber.number;
    }
  } catch (error) {
    // ignore
  }

  return '';
}
