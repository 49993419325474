import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { locationChange } from '../slices/ui';
import { track } from '../utils/tracking';

export default function useLocationTracker() {
  const dispatch = useDispatch();

  const location = useLocation();
  useEffect(() => {
    dispatch(locationChange({ location }));

    const { pathname } = location;
    track('view.page', { Page: pathname });
  }, [location]);
}
