import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { identify, register } from '../utils/tracking';

export default function useUserTracker() {
  // Assumption: user's kind doesn't change
  const userKind = useSelector(state => state.user.kind);
  const featureFlags = useSelector(state => state.featureFlags);

  register({
    Kind: userKind,
    'Feature Flags': featureFlags, 
  });

  const userId = useSelector(state => state.user.id);
  useEffect(() => {
    if (userId) {
      identify(userId);
    }
  }, [userId]);
}
