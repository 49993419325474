import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './App';
import configureAppStore from './store';

if (window.SentryDSN) {
  Sentry.init({ dsn: window.SentryDSN });
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('app');
  const store = configureAppStore(node);

  ReactDOM.render(<App {...{ store }} />, node);
});
