import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import camelCaseKeys from 'camelcase-keys';

export const reload = createAsyncThunk(
  'shoutOuts/reload',
  async () => {
    const response = await fetch('/shout_outs');
    return response.json();
  }
);

export const fetchOlder = createAsyncThunk(
  'shoutOuts/fetchOlder',
  async (_, { getState }) => {
    const { shoutOuts: { olderCursor } } = getState();
    const response = await fetch(
      `/shout_outs?${new URLSearchParams({ cursor: olderCursor })}`
    );

    return response.json();
  }
);

export const shoutOutsSlice = createSlice({
  name: 'shoutOuts',
  initialState: {
    data: [],
    hasMore: true,
    olderCursor: '',
    newerCursor: '',
    requestInProgress: false,
  },
  reducers: {
    // Nothing
  },
  extraReducers: {
    [reload.pending]: state => {
      state.requestInProgress = true;
    },
    [reload.fulfilled]: (state, { payload }) => {
      return {
        requestInProgress: false,
        ...camelCaseKeys(payload),
      };
    },
    [reload.rejected]: state => {
      state.requestInProgress = false;
    },
    [fetchOlder.pending]: state => {
      state.requestInProgress = true;
    },
    [fetchOlder.fulfilled]: (state, { payload }) => {
      state.requestInProgress = false;

      const { data, hasMore, olderCursor } = camelCaseKeys(payload);
      state.data.push(...data);
      state.hasMore = hasMore;
      state.olderCursor = olderCursor;
    },
    [fetchOlder.rejected]: state => {
      state.requestInProgress = false;
      state.hasMore = false;
    },
  },
});

export default shoutOutsSlice.reducer;
