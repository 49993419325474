import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function AppNav() {
  const showNav = useSelector(state => state.ui.showNav);

  return showNav && (
    <div className="app-nav">
      <Navbar className="px-0">
        <Navbar.Brand className="mx-auto">
          Nurse Shout Outs
        </Navbar.Brand>
      </Navbar>
      <Nav fill>
        <Nav.Item>
          <NavLink className="nav-link" to="/app/feed">
            Shout Out Feed
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className="nav-link" to="/app/my-feed">
            My Shout Outs
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className="nav-link" to="/app/how-it-works">
            How It Works
          </NavLink>
        </Nav.Item>
      </Nav>
    </div>
  );
}
