import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import chunk from 'lodash/chunk';
import Row from 'react-bootstrap/Row';
import once from 'lodash/once';

import { track } from '../utils/tracking';
import Badge, { BadgeType } from './Badge';

export default function BadgeSelect({ error, selected, setSelected, size }) {
  const badges = useSelector(state => state.badges);

  const trackEvent = useRef(once(() => {
    track('select.badge');
  }));

  const wrappedSetSelected = badge => {
    setSelected(badge);
    trackEvent.current();
  };

  return (
    <div className={classnames('badge-select', { errored: !!error })}>
      {
        chunk(badges, 2).map(
          chunked => (
            <Row
              key={chunked[0].id}
              className="d-flex justify-content-center"
            >
              {
                chunked.map(badge => (
                  <Badge
                    key={badge.id}
                    selected={selected?.id === badge.id}
                    handleClick={wrappedSetSelected}
                    size={size}
                    badge={badge}
                  />
                ))
              }
            </Row>
          )
        )
      }
      {
        error && (
          <div className="input-error">
            {error}
          </div>
        )
      }
    </div>
  );
}

BadgeSelect.propTypes = {
  error: PropTypes.string,
  selected: BadgeType,
  setSelected: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['medium', 'small']),
};

BadgeSelect.defaultProps = {
  error: null,
  selected: null,
  size: 'medium',
};
