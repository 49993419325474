import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function CountdownContainer({ children }) {
  const remaining = useSelector(state => state.user.shoutOutAllowance);

  return (
    <div className="countdown-container">
      <header className="countdown-header">
        Shout Outs Remaining: {remaining}
      </header>

      { children }
    </div>
  );
}

CountdownContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
