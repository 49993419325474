import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import camelCaseKeys from 'camelcase-keys';

import { reload } from '../slices/shoutOuts';
import { updateUser } from '../slices/user';
import { fetchByPhone } from '../slices/userShoutOuts';
import { createShoutOut } from '../utils/api';
import { validateShoutOut } from '../utils/validators';

import AppButton from './AppButton';
import BadgeSelect from './BadgeSelect';
import ConfirmationBlock from './ConfirmationBlock';
import CountdownContainer from './CountdownContainer';
import Input from './Input';

function makeEmptyForm(user) {
  return {
    shoutOut: {
      recipientName: '',
      recipientPhone: '',
      senderName: user.name,
      senderPhone: user.phone,
      badge: null,
      note: '',
    },
    errors: new Map(),
  };
}

export default function ShoutOutFormWithCountdown({ handleSubmit }) {
  const user = useSelector(state => state.user);
  const showConfirmationBlock = useSelector(
    state => state.ui.showShoutOutSentConfirmation
  );
  const dispatch = useDispatch();

  const [form, setForm] = useState(makeEmptyForm(user));
  const [submitting, setSubmitting] = useState(false);

  const updateShoutOut = fieldName => (
    value => setForm(
      ({ shoutOut, errors }) => {
        errors.delete(fieldName);

        return {
          shoutOut: {
            ...shoutOut,
            [fieldName]: value,
          },
          errors,
        };
      }
    )
  );

  const submit = async () => {
    setSubmitting(true);

    const { data, errors } = validateShoutOut(form.shoutOut);

    if (errors.size > 0) {
      setForm(prevState => ({ ...prevState, errors }));
      setSubmitting(false);
    } else {
      data.sender.kind = user.kind;

      try {
        const response = await createShoutOut(data);
        const returnedUser = camelCaseKeys(response.user);
        setSubmitting(false);

        dispatch(updateUser(returnedUser));
        dispatch(fetchByPhone(returnedUser.phone));
        dispatch(reload());

        setForm(makeEmptyForm(returnedUser));
        handleSubmit(returnedUser);
      } catch (err) {
        setSubmitting(false);
        console.error(err);
      }
    }
  };

  let mainBlock;
  if (showConfirmationBlock) {
    mainBlock = <ConfirmationBlock />;
  } else {
    const shortNotePlaceholder = form.shoutOut.badge && (
      `example: "${form.shoutOut.badge.sampleNote}"`
    );

    mainBlock = (
      <CountdownContainer>
        <BadgeSelect
          error={form.errors.get('badge')}
          selected={form.shoutOut.badge}
          setSelected={updateShoutOut('badge')}
          size="small"
        />

        <Input
          className="recipient-name-input"
          controlId="recipientName"
          label="Nurse Name"
          value={form.shoutOut.recipientName}
          onChange={updateShoutOut('recipientName')}
          error={form.errors.get('recipientName')}
          eventControlPrefix="send_shout_out"
        />

        <Input
          controlId="recipientPhone"
          label="Nurse Phone Number"
          type="tel"
          value={form.shoutOut.recipientPhone}
          onChange={updateShoutOut('recipientPhone')}
          xs={9}
          error={form.errors.get('recipientPhone')}
          eventControlPrefix="send_shout_out"
        />

        <Input
          controlId="note"
          label="Short note"
          optional
          type="textarea"
          value={form.shoutOut.note}
          onChange={updateShoutOut('note')}
          error={form.errors.get('note')}
          eventControlPrefix="send_shout_out"
          rows={5}
          placeholder={shortNotePlaceholder}
        />

        <Row className="justify-content-center">
          <AppButton
            className="submit-button"
            disabled={submitting || form.errors.size > 0}
            icon={submitting ? 'spinner' : 'paperPlane'}
            clickName="submit_shout_out"
            onClick={submit}
            text="Send"
          />
        </Row>
      </CountdownContainer>
    );
  }

  return (
    <Form className="shout-out-form countdown-form">
      <Input
        className="sender-name-input"
        controlId="senderName"
        label="Your Name"
        value={form.shoutOut.senderName}
        onChange={updateShoutOut('senderName')}
        error={form.errors.get('senderName')}
        eventControlPrefix="send_shout_out"
      />

      <Input
        controlId="senderPhone"
        label="Your Phone Number"
        type="tel"
        value={form.shoutOut.senderPhone}
        onChange={updateShoutOut('senderPhone')}
        xs={9}
        error={form.errors.get('senderPhone')}
        eventControlPrefix="send_shout_out"
      />

      { mainBlock }
    </Form>
  );
}

ShoutOutFormWithCountdown.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
